.section-intro {
    // background: -webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)),
    //   url("../../images/homepage-video.webp") no-repeat center center;
    // background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)),
    //   url("../../images/homepage-video.webp") no-repeat center center;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)),
      url("../../images/hero.jpg") no-repeat center center;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
      url("../../images/hero.jpg") no-repeat center center;
    background-size: cover;
    /* background: url("https://live.staticflickr.com/65535/50685960898_b00acf251b_k.jpg"); */
    background-color: #4C14A3;
  }

  .m-60vh {
    min-height: 60vh;
  }

@media (max-width: 960px) {
  .head-map {
      height: 201.333px !important;
  }
}

.full-map-container {
  position: absolute;
  width: 100%;
  // height: calc(100% - 160px);
  top: 80px;
  right: 0;
  bottom: 80px;
  left: 0;
  z-index: 500;
}